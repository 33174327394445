<template>
    <div class="SetPromotion">
        <div class="SetPromotionHeader">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>营销工具</el-breadcrumb-item>
                <el-breadcrumb-item>优惠券</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="headerLeft">
                <p>优惠券</p>
                <div class="headerLeftBtn">
<!--                    <el-button class="btn" @click="deleSelectPromotion">结束推广</el-button>-->
                    <el-button class="btn btn-red" @click="toPromotionAdd" :disabled="tableData.length > 0">新建优惠</el-button>
                </div>
            </div>
        </div>
        <div class="SetPromotionTable">
            <el-table :data="tableData" style="width: 100%;flex: 1;margin-top: 20px;border: 1px solid #DCDCDC;height: calc(100vh - 250px);overflow-y: auto" :cell-style="{height: '70px',color:'#333333', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:500}" :header-cell-style="{fontWeight: '500', color: '#666666', background: '#F5F5F5',height: '46px'}">
                <el-table-column label="优惠券名称" prop="name"></el-table-column>
                <el-table-column label="优惠信息" prop="activity_detail"></el-table-column>
                <el-table-column label="促销方式" prop="promotionWay" align="center">优惠券</el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" style="color: #1E63F1" @click="editBtn()">修改</el-button>
                        <el-button type="text" style="color: #FD4446" @click="delePromotion(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        name: "Coupon",
        data(){
            return {
                tableData:[],
                coupon_id: null,
                op_market_id: '',
                stu_id: '',
            }
        },
        mounted() {
            this.getCouponList();
        },
        computed: {
            menus() {
                return this.$store.getters.dataMenuList;
            }
        },
        methods: {
            ...mapActions([
                'setDataMenusList'
            ]),
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //获取优惠券列表
            getCouponList() {
                this.$http.axiosGetBy(this.$api.showStuMarket, {type: '4'}, (res) =>{
                    if (res.code === 200) {
                        this.tableData = res.data.data[0].stu_market_data;
                        this.coupon_id = res.data.data[0].id;
                        this.op_market_id = res.data.data[0].op_market_id;
                        this.stu_id = res.data.data[0].stu_id;
                        if (this.$common.isCompetion()) {
                            let menus = this.menus;
                            let currentStatus = menus[1].children[3].status;
                            if (Number(currentStatus) === 0) {
                                this.operation_updateStatus(1, 3, 1);
                            }
                        }
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            toPromotionAdd(){
                this.$router.push({
                    path: '/student/operationpromotion/couponAdd'
                })
            },
            //结束推广
            deleSelectPromotion(){
                this.$confirm('确定要结束推广吗？, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        op_market_id: this.op_market_id,
                        stu_id: this.stu_id
                    };
                    this.$http.axiosGetBy(this.$api.endStudentMarket, param, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getCouponList();
                        } else {
                            this.$message.warning(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //编辑
            editBtn() {
                this.$router.push({
                    path: '/student/operationpromotion/couponone',
                    query: {
                        id: this.coupon_id
                    }
                })
            },
            //删除
            delePromotion(id){
                this.$confirm('确定要删除该优惠吗？, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.delStudentMarket, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.getCouponList();
                        } else {
                            this.$message.warning(res.msg);
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.el-breadcrumb .el-breadcrumb__item .el-breadcrumb__inner{
        color: #9A9A9A;
        font-size: 14px;
    }
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    .SetPromotion{
        .SetPromotionHeader{
            .headerLeft{
                display: flex;
                justify-content: space-between;
                p{
                    color: #333333;
                    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 1;
                    margin: 20px 0 0 0;
                }
                .headerLeftBtn{
                    .btn{
                        width: 98px;
                        height: 40px;
                        border-color: #FD4446;
                        line-height: 0;
                    }
                    /*.btn:nth-child(1){*/
                        /*background: #F7F9FB;*/
                        /*color: #FD4446;*/
                    /*}*/
                    .btn:nth-child(2){
                        background: #FD4446;
                        color: #ffffff;
                    }
                }
                /deep/ .el-button.is-disabled {
                    background-color: #DCDCDC !important;
                    border-color: #DCDCDC !important;
                    color: #fff !important;
                }
            }
        }
        .SetPromotionTable {
            /*min-height: calc(100vh - 232px);*/
            margin-top: 18px;
            background: #ffffff;
            padding: 20px;
            /*/deep/ .el-table {*/
            /*    min-height: calc(100vh - 272px);*/
            /*}*/
        }
    }
</style>